<template>
  <div class="pricing-box">
    <div class="pricing-title">
      <h3>{{ item.name }}</h3>
    </div>
    <div class="pricing-features">
      <div class="price-desc" v-html="getDescription"></div>
    </div>
    <div class="pricing-price">
      <span class="price-unit">$</span>{{ price }} <span
        style="font-size:14px; padding-top:35px;">{{ channelSettings.currency }}</span>
    </div>
    <div class="pricing-action d-flex flex-column">
      <div class="container" v-if="item.is_team_pass && participants.length">
        <div class="team-select" style="height:100px;">
          <span>Select Team:</span>
          <select :v-model="getSelectedParticipant()" class="form-control" @input="inputParticipants"
                  style="background-color: #fff;" required="">
            <option v-for="participant in participants"
                    :key="participant.id"
                    :value="participant.id">{{ participant.name }}
            </option>
          </select>
        </div>
      </div>
      <app-link v-if="!packageBought" :to="routePath" class="btn btn-block btn-lg buy-button">
        {{ $t('Buy') }}
      </app-link>
      <div v-else class="btn btn-block btn-lg">{{$t('purchased')}}</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {mapCacheActions} from "vuex-cache";
import AppLink from "../../../components/base-components/AppLink";
import {PACKAGE_TYPES} from "@/const";

export default {
  name: "PackageComponent",
  components: {AppLink},
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      selectedParticipant: '',
      link: '',
      packageBought: false,
      price: this.item.base_price,
    }
  },
  methods: {
    ...mapCacheActions(['GET_ORDERS', 'GET_PARTICIPANTS_LIST']),
    getSelectedParticipant() {
      if (this.participants && !this.selectedParticipant) {
        this.selectedParticipant = this.participants[0].id;
      }
      return this.selectedParticipant
    },
    inputParticipants(el) {
      if (this.item.is_team_pass) {
        this.link = {
          package_id: this.item.id,
          participant_id: el.target.value
        }
      } else {
        this.link = {
          package_id: this.item.id,
        }
      }
    },
    buttonStatus() {
      if (!this.$store.getters.isLogged) {
        return false;
      }

      this.GET_ORDERS({type: 'package', status: 'paid'}).then(orders => {
        if (orders) {
          let exists = orders.find(order => {
            if (order.product.id == this.item.id) {
              return true;
            }
          });

          if (exists) {
            this.packageBought = true;
          }
        }

      });
    }
  },
  created() {
    this.buttonStatus();
  },
  computed: {
    ...mapState({
      participants: state => state.participant.participantsList,
      channelSettings: state => state.channel.settings,
    }),
    getDescription() {
      return this.convertEditorJsonToHtml(this.item.description) ?? '';
    },
    routePath(){
      if (this.item.type === PACKAGE_TYPES.SINGLE) {
        return {
          name: 'Upcoming', query: {
            package_id: this.item.id
          }
        }
      }
      return {name: 'buy', query: this.link}
    }

  },
  mounted() {
    if (this.item.is_team_pass) {
      this.GET_PARTICIPANTS_LIST().then((data) => {
        this.link = {
          package_id: this.item.id,
          participant_id: data.data[0].id
        }
      });
    } else {
      this.link = {
        package_id: this.item.id,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/colors";

.package-block {
  width: 100%;
}

.price-desc {
  padding: 1.4rem;
  flex-grow: 1;
}

.pricing-box {
  position: relative;
  border: 1px solid rgba($button-primary-rgb, 0.075);
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 1px 1px rgba($button-primary-rgb, 0.1);
  background-color: $card-background;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: .8rem;
}

.pricing-title {
  height: 5rem;
  padding: 15px 0;
  background-color: $card-title-background;
  border-radius: 3px 3px 0 0;
  text-shadow: 1px 1px 1px $text-secondary;
  border-bottom: 1px solid rgba($button-primary-rgb, 0.05);
  overflow: hidden;

  h3 {
    margin: 0;
    font-size: 27px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: $text-tertiary-dark;
  }
}

.pricing-price {
  position: relative;
  padding: 25px 0;
  color: $text-primary;
  font-weight: 300;
  font-size: 40px;
  line-height: 1;
  border-bottom: 1px solid rgba($button-primary-rgb, 0.075);
}

.pricing-price:before {
  content: '';
  position: absolute;
  width: 100px;
  border-top: 1px solid rgba($button-primary-rgb, 0.1);
  top: 0;
  left: 50%;
  margin-left: -50px;
}

.pricing-features {
  min-height: 10rem;
  flex-grow: 1;

  ul {
    margin: 0;
    padding: 15px 0;
    list-style: none;
  }

  li {
    padding: 6px 0;
    font-size: 14px;
  }
}

.pricing-action {
  padding: 15px 30px;

  .btn {
    margin-top: 3.8rem;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.19rem + 2px);
  width: 100%;
}

.team-select {
  margin-top: -.7rem;
  margin-bottom: -5.4rem;
}

.buy-button {
  background-color: var(--settings-package-background);
  border: none;
  color: var(--settings-package-text-color);
  &:hover {
    color: var(--settings-package-text-color);
  }
}
</style>
