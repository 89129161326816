<template>
  <router-link :to="to" v-bind="$attrs" custom v-slot="{ href }">
    <a v-if="isExternal" v-bind="$attrs" target="_blank" :href="to">
      <slot>{{ to }}</slot>
    </a>
    <router-link v-else v-bind="$attrs" :to="to"><slot /></router-link>
  </router-link>
</template>

<script>
export default {
  name: "AppLink",
  props: {
    to: {
      required: true,
    },
  },
  computed: {
    isExternal() {
      return typeof this.to === "string" && this.to.startsWith("http");
    },
  },
};
</script>

<style scoped></style>
